import { navigate } from "gatsby";
import React, { ReactNode } from "react";
import { useString } from "../../i18n/GetText";
import { ToolbarItem } from "../../model/menu";
import { SanityRawContent } from "../../model/common";
import { urlForDocument } from "../../urls";
import { useGatsbyLocation } from "../../utils/useGatsbyLocation";
import BlockContentOutline from "../BlockContentOutline/BlockContentOutline";
import Link from "../Link/Link";
import styles from "./LeftNav.module.scss";
import SelectDropdown, {
  DropdownOption
} from "../SelectDropdown/SelectDropdown";
import Text from "../Text/Text";
import Divider from "../Divider/Divider";

interface LeftNavProps {
  className?: string;
  title?: ReactNode;
  items: ToolbarItem[];
  /**
   * True to flip to a drop-down for mobile.
   */
  mobile?: boolean;
  /**
   * Shows an outline of this content under the current page.
   */
  contentForOutline?: SanityRawContent;
}

const LeftNav = ({ title, mobile, items, contentForOutline }: LeftNavProps) => {
  const { pathname } = useGatsbyLocation();
  const selectOptions: DropdownOption[] = items.map(
    ({ title: itemTitle, internalLink, internalLinkVariant }: ToolbarItem) => ({
      label: (
        <Text variant="default" className={styles.option}>
          {itemTitle}
        </Text>
      ),
      value: urlForDocument(internalLink!, internalLinkVariant)
    })
  );
  const matchUrl = selectOptions.find(
    (n: DropdownOption) => n.value === pathname
  );
  const overrideStyles = {
    singleValue: {
      "& *": {
        color: "rgb(38, 38, 38);",
        fontWeight: 700
      }
    }
  };
  const ariaLabel = useString("select-section");
  return (
    <>
      {mobile && (
        <SelectDropdown
          onChange={(e: DropdownOption) => {
            navigate(e.value as string);
          }}
          className={styles.dropdown}
          defaultValue={matchUrl}
          overrideStyles={overrideStyles}
          isSearchable={false}
          options={selectOptions}
          aria-label={ariaLabel}
        />
      )}
      <nav className={styles.nav}>
        {title && <Text variant="h4">{title}</Text>}
        <ul className={styles.list}>
          {items.map(
            (
              { internalLink, internalLinkVariant, title: itemTitle, _key },
              index: number
            ) => {
              const outline = contentForOutline &&
                urlForDocument(internalLink!, internalLinkVariant) ===
                  pathname && (
                  <BlockContentOutline
                    subMenuStyle={true}
                    content={contentForOutline}
                  />
                );
              return (
                <React.Fragment key={_key + internalLinkVariant}>
                  {index > 0 && <Divider className={styles.divider} />}
                  <li className={styles.listItem}>
                    <Link
                      to={urlForDocument(internalLink!, internalLinkVariant)}
                      className={styles.link}
                      activeClassName={styles.activeLink}
                    >
                      <Text
                        variant={contentForOutline ? "defaultBold" : "default"}
                        gutterBottom={!!outline}
                      >
                        {itemTitle}
                      </Text>
                    </Link>
                    {outline}
                  </li>
                </React.Fragment>
              );
            }
          )}
        </ul>
      </nav>
    </>
  );
};

export default LeftNav;
