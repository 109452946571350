import React from "react";
import { Col, Row } from "../../components/Grid";
import Link from "../../components/Link/Link";
import { URLParts, urlForDocument } from "../../urls";
import styles from "./RelatedContent.module.scss";
import Card from "../../components/Card/Card";
import Text from "../Text/Text";

const RelatedContent = ({ content }: RelatedContentProps) => {
  return (
    <Row className={styles.root}>
      {content &&
        content
          .filter(c => !!c.internalLink)
          .map((c, index) => (
            <Col key={index} xs={12} sm={6}>
              <Link to={urlForDocument(c.internalLink)}>
                <Card className={styles.card}>
                  <Text variant="h3">{c.title}</Text>
                  <Text variant="default">{c.description}</Text>
                </Card>
              </Link>
            </Col>
          ))}
    </Row>
  );
};

export default RelatedContent;

interface RelatedContentProps {
  content: SanityRelatedContent[];
}

export interface SanityRelatedContent {
  _type: string;
  title: string;
  description: string;
  internalLink: URLParts;
}
