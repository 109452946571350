import React from "react";
import { CustomEmbeds } from "../../components/BlockContent/BlockContent";
import FeaturesUserGuideContent from "../../components/FeaturesUserGuideContent/FeaturesUserGuideContent";
import LeftNav from "../../components/LeftNav/LeftNav";
import { SanityRelatedContent } from "../../components/RelatedContent/RelatedContent";
import TitleRow from "../../components/TitleRow/TitleRow";
import VisuallyHidden from "../../components/VisuallyHidden/VisuallyHidden";
import { useString } from "../../i18n/GetText";
import { ContentFields, SanityCampaign } from "../../model/common";
import { ToolbarItem } from "../../model/menu";
import TwoColumnLayout from "../TwoColumnLayout/TwoColumnLayout";

export interface LeftNavLayoutProps extends ContentFields {
  campaigns: SanityCampaign[];
  h1TextId: string;
  h2: string;
  menu: ToolbarItem[];
  relatedContent?: SanityRelatedContent[];
  customEmbeds?: CustomEmbeds;
}

export const LeftNavLayout = ({
  campaigns,
  menu,
  h1TextId,
  h2,
  content,
  customEmbeds,
  relatedContent,
  licence,
  translationSponsors
}: LeftNavLayoutProps) => {
  const h1 = useString(h1TextId);
  return (
    <TwoColumnLayout
      topRows={
        <>
          <VisuallyHidden>
            <h1>{`${h1}: ${h2}`}</h1>
          </VisuallyHidden>
          <TitleRow
            title={h1}
            socialTitle={`${h1} - ${h2}`}
            ariaHiddenTitle={true}
          />
        </>
      }
      aside={<LeftNav mobile items={menu} contentForOutline={content} />}
      main={
        <FeaturesUserGuideContent
          customEmbeds={customEmbeds}
          content={content}
          licence={licence}
          translationSponsors={translationSponsors}
          relatedContent={relatedContent}
        />
      }
      campaigns={campaigns}
    />
  );
};

export default LeftNavLayout;
