import React from "react";
import { CustomEmbeds } from "../../components/BlockContent/BlockContent";
import Card from "../../components/Card/Card";
import CreditedContent from "../../components/CreditedContent/CreditedContent";
import { ContentFields } from "../../model/common";
import RelatedContent, {
  SanityRelatedContent
} from "../RelatedContent/RelatedContent";
import styles from "./FeaturesUserGuide.module.scss";

interface FeaturesUserGuideContentProps extends ContentFields {
  relatedContent?: SanityRelatedContent[];
  customEmbeds?: CustomEmbeds;
}

const FeaturesUserGuideContent = ({
  relatedContent,
  ...others
}: FeaturesUserGuideContentProps) => {
  return (
    <>
      <Card className={styles.card} type="main">
        <CreditedContent
          {...others}
          className={styles.blockContent}
          cap="3/4"
        />
      </Card>
      {relatedContent && <RelatedContent content={relatedContent} />}
    </>
  );
};

export default FeaturesUserGuideContent;
